<template>
  <v-container fluid>
    <v-row class="ma-0 pa-0">
      <!-- Subscribed Plan and User Profile  -->
      <v-col v-if="showZoho" cols="12" md="7">
        <v-row class="pt-2">
          <v-col cols="12" md="4">
            <v-card
              color="secondarygrad"
              class="rounded align-center pa-6 pt-4"
              style="min-height: 122px;"
            >
              <h3 style="color:#30475A;" class="blue-black">
                Cycle
              </h3>
              <div class="pt-2" style="color: grey;">
                {{ companyDetails.plan.name }}
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card
              color="secondarygrad"
              class="rounded align-center pa-6 pt-4"
              style="min-height: 122px;"
            >
              <h3 style="color:#30475A;" class="blue-black">
                Expiry
              </h3>
              <div class="pt-2" style="color: grey;">
                {{ companyDetails.plan.expiryDate | moment("lll") }}
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card
              color="secondarygrad"
              class="rounded align-center pa-6 pt-4"
            >
              <h3 style="color:#30475A;" class="blue-black">
                Member Limit
              </h3>
              <div class="pt-2" style="color: grey;">
                {{ companyDetails.plan.userLimit }}
              </div>
              <div
                style="display: flex; justify-content: flex-end; margin-top: -6px; color: grey;font-size: 16px;"
              >
                <span class="liveAccount" style="margin-top: 7px;"></span>
                <i v-if="originalUserCount > 1"
                  >{{ originalUserCount }} added users</i
                >
                <i v-else-if="originalUserCount <= 1"
                  >{{ originalUserCount }} added user</i
                >
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="pt-2">
          <v-col>
            <zoho-subscription
              v-if="showZoho"
              ref="zohoSubscriptionBox"
              @update-original-user-count="handleOriginalUserCountUpdate"
            />
            <!-- <paddle-subscription
              v-else
              @update-original-user-count="handleOriginalUserCountUpdate"
            /> -->
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="!showZoho" cols="12" md="12">
        <v-row class="pt-2">
          <v-col cols="12" md="4">
            <v-card
              color="secondarygrad"
              class="rounded align-center pa-6 pt-4"
              style="min-height: 122px;"
            >
              <h3 style="color:#30475A;" class="blue-black">
                Cycle
              </h3>
              <div class="pt-2" style="color: grey;">
                {{ companyDetails.plan.name }}
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card
              color="secondarygrad"
              class="rounded align-center pa-6 pt-4"
              style="min-height: 122px;"
            >
              <h3 style="color:#30475A;" class="blue-black">
                Expiry
              </h3>
              <div class="pt-2" style="color: grey;">
                {{ companyDetails.plan.expiryDate | moment("lll") }}
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card
              color="secondarygrad"
              class="rounded align-center pa-6 pt-4"
            >
              <h3 style="color:#30475A;" class="blue-black">
                Member Limit
              </h3>
              <div class="pt-2" style="color: grey;">
                {{ companyDetails.plan.userLimit }}
              </div>
              <div
                style="display: flex; justify-content: flex-end; margin-top: -6px; color: grey;font-size: 16px;"
              >
                <span class="liveAccount" style="margin-top: 7px;"></span>
                <i v-if="originalUserCount > 1"
                  >{{ originalUserCount }} added users</i
                >
                <i v-else-if="originalUserCount <= 1"
                  >{{ originalUserCount }} added user</i
                >
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="pt-2">
          <v-col>
            <!-- <zoho-subscription
              v-if="showZoho"
              ref="zohoSubscriptionBox"
              @update-original-user-count="handleOriginalUserCountUpdate"
            /> -->
            <paddle-subscription
              @update-original-user-count="handleOriginalUserCountUpdate"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="showZoho" cols="12" md="5">
        <payment-profile
          @update-original-user-count="handleOriginalUserCountUpdate"
        />

        <!-- <promo-code
          v-if="showZoho"
          @updateBill="$refs.zohoSubscriptionBox.getBillingCost()"
          @update-original-user-count="handleOriginalUserCountUpdate"
        /> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import zohoSubscription from "../components/subscription/ZohoSubscription.vue";
import paymentProfile from "../components/subscription/PaymentProfile.vue";
import PaddleSubscription from "../components/subscription/PaddleSubscription.vue";
import promoCode from "../components/subscription/promocode.vue";
export default {
  components: {
    zohoSubscription,
    paymentProfile,
    PaddleSubscription,
    promoCode,
  },
  data() {
    return {
      tabs: null,
      tabs1: null,
      switch1: true,
      name: "",
      checkbox: true,
      originalUserCount: 1,
      slider: 1,
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      paymentType: [
        { name: "Automatic Payments", value: "automatic" },
        { name: "Manual Payment", value: "manual" },
      ],
      showZoho: true,
      // lastPlan: {},
    };
  },
  computed: {
    moneyTrial() {
      return require("../assets/moneyTrial.png");
    },
    paperplane() {
      return require("../assets/paperplane.png");
    },
    plane() {
      return require("../assets/plane.png");
    },
    companyDetails() {
      return this.$store.getters.companyDetails;
    },
    isActive() {
      return this.companyDetails.plan.active;
    },
  },
  created() {
    // this.getGeoLocViaIP();
    this.getLastPlan();
  },
  methods: {
    handleOriginalUserCountUpdate(newOriginalCount) {
      this.originalUserCount = newOriginalCount;
    },
    setPaymentType(type) {
      this.selectedPaymentType = type;
    },
    getLastPlan() {
      var plan = this.companyDetails.plan;
      if (plan.name == "Trial") {
        // Trial Check Country
        this.showZoho = false;
        if (this.companyDetails.country == "India") {
          this.showZoho = true;
        }
      } else {
        // Check Previous Plan
        this.showZoho = true;
        if (plan.name == "GoDial Premium") {
          this.showZoho = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.v-toolbar__content {
  height: 0 !important;
}
.customBorder {
  border-style: solid;
  padding: 5px;
  margin: 10px;
  border-radius: 4px;
  width: 100%;
}
.centerimg {
  position: absolute;
  top: 30%;
  left: 30%;
}

.liveAccount {
  /* position: absolute; */
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: rgb(95, 255, 47);
  margin: 12px 10px;
}

.blue-black {
  color: #30475a;
}
</style>
