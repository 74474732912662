<template>
  <div>
    <v-card class="rounded my-2">
      <v-card-title class="my-1 py-1 primarygrad">
        <h3>Promo Code</h3>
      </v-card-title>
      <v-card-text class="ma-1 pa-2">
        <v-row justify="center" align="center">
          <v-col cols="8" sm="8" style="padding-top: 36px;">
            <v-text-field
              v-model="promoCode"
              label="Promo Code"
              solo
              type="text"
              background-color="inverted"
              :disabled="hasPromoCode"
              @keyup="uppercase"
            ></v-text-field>
          </v-col>

          <v-col v-if="!hasPromoCode">
            <v-btn class="secondary inverted--text" @click="applyPromo">
              Apply
            </v-btn>
          </v-col>
          <v-col v-else>
            <v-btn class="secondary" @click="removePromo">
              Remove
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const _ = require("lodash");
import fb from "../../js/firebase";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      promoCode: "",
      loader: false,
    };
  },
  computed: {
    ...mapGetters(["user", "companyDetails"]),

    hasPromoCode() {
      return typeof this.$store.getters.companyDetails.paymentProfile
        .promoCode != "undefined" &&
        this.$store.getters.companyDetails.paymentProfile.promoCode != ""
        ? true
        : false;
    },
  },
  created() {
    this.init();
  },
  methods: {
    uppercase() {
      this.promoCode = this.promoCode.toUpperCase().trim();
    },
    async init() {
      await this.$store.dispatch("getCompanyDetails", this.user);

      this.promoCode =
        typeof this.companyDetails.paymentProfile.promoCode != "undefined"
          ? this.companyDetails.paymentProfile.promoCode
          : "";

      this.$emit("updateBill");
    },
    removePromo() {
      this.$http
        .post(`${this.$store.state.ENDPOINT}/promos/promo-remove-company`)
        .then((respo) => {
          this.init();
          return this.$swal({
            type: "success",
            text: "Promo Code Successfully Applied.",
          });
        })
        .catch((err) => {
          console.log("Error On Apply Promo", err);
          return this.$swal({
            type: "error",
            text: err.body.error.message,
          });
        });
    },
    applyPromo() {
      this.$http
        .post(`${this.$store.state.ENDPOINT}/promos/promo-check-apply`, {
          promoCode: this.promoCode.toUpperCase().trim(),
          totalUser: this.userCount,
        })
        .then((respo) => {
          this.init();

          return this.$swal({
            type: "success",
            text: "Promo Code Successfully Applied.",
          });
        })
        .catch((err) => {
          console.log("Error On Apply Promo", err);
          return this.$swal({
            type: "error",
            text: err.body.error.message,
          });
        });
    },
  },
};
</script>

<style scoped>
.v-toolbar__content {
  height: 0 !important;
}
.customBorder {
  border-style: solid;
  padding: 5px;
  margin: 10px;
  border-radius: 4px;
  width: 100%;
}
.centerimg {
  position: absolute;
  top: 30%;
  left: 30%;
}

/* Ribbon ************ */

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: 69px;
  right: 0px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: 1px;
  top: 22px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
@media (min-width: 1200px) {
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .smallMobFont {
    font-size: 20px !important;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .smallMobFont {
    font-size: 20px !important;
  }
}

.primarygrad {
  background-image: linear-gradient(to right, #92df48, #1bca3f) !important;
  color: white;
}
</style>
