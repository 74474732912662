<template>
  <div>
    <v-card flat class="transparent ma-0 pa-0">
      <v-row class="ma-0 pa-0">
        <!-- User Profile-->
        <v-col cols="12" class="ma-0 pa-0">
          <v-alert
            v-if="companyDetails.paymentProfile.activated == false"
            type="warning"
          >
            Please complete and save you profile</v-alert
          >
          <v-card class="rounded  my-2 pt-1">
            <v-card class="rounded my-2">
              <v-card-title class="white--text my-1 py-1">
                <h4 style="color:#30475A;">Payment Profile</h4>
              </v-card-title>
              <v-card-text class="ma-1 pa-2">
                <v-text-field
                  v-model="paymentProfile.company"
                  label="Company Name *"
                />
                <v-row class="pa-0 ma-0">
                  <v-col cols="6" class="py-0 pl-0 ma-0">
                    <v-text-field
                      v-model="paymentProfile.firstName"
                      label="First Name *"
                    />
                  </v-col>
                  <v-col cols="6" class="py-0 pr-0 ma-0">
                    <v-text-field
                      v-model="paymentProfile.lastName"
                      label="Last Name *"
                    />
                  </v-col>
                </v-row>
                <v-text-field v-model="paymentProfile.email" label="E-mail *" />
                <v-text-field
                  v-model="paymentProfile.phone"
                  label="Phone *"
                  class="pt-3"
                />
                <v-text-field
                  v-model="paymentProfile.address"
                  label="Address *"
                  class="pt-3"
                />
                <v-autocomplete
                  v-model="paymentProfile.state"
                  :items="indianStates"
                  item-text="name"
                  item-value="name"
                  label="State *"
                  autocomplete="false"
                />
                <v-row class="pa-0 ma-0">
                  <v-col cols="6" class="py-0 pl-0 ma-0">
                    <v-text-field
                      v-model="paymentProfile.city"
                      label="City *"
                    />
                  </v-col>
                  <v-col cols="6" class="py-0 pr-0 ma-0">
                    <v-text-field
                      v-model="paymentProfile.zip"
                      label="Pincode *"
                      type="number"
                    />
                  </v-col>
                </v-row>
                <v-autocomplete
                  v-model="paymentProfile.country"
                  :items="countries"
                  label="Country *"
                  item-text="name"
                  item-value="name"
                  prepend-inner-icon="location_on"
                  autocomplete="false"
                />
                <v-text-field v-model="paymentProfile.gst_no" label="GSTIN" />

                <div class="text-center">
                  <v-btn class="primarygrad" dark @click="setBillingProfile">
                    {{ buttonValue }}
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import countryList from "../../js/countries";
import indoStates from "../../js/indianStates";

export default {
  data() {
    return {
      paymentProfile: {
        activated: false,
        company: "",
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        city: "",
        state: "West Bengal",
        country: "India",
        zip: "",
        phone: "",
        gst_no: "",
      },
      indianStates: indoStates,
      countries: countryList,
      buttonValue: "Save",
      loader: false,
    };
  },
  computed: {
    moneyTrial() {
      return require("../../assets/moneyTrial.png");
    },
    companyDetails() {
      return this.$store.getters.companyDetails;
    },
    user() {
      return this.$store.getters.user;
    },
    endpoint() {
      return this.$store.getters.ENDPOINT;
    },
  },
  created() {
    const self = this;
    self.checkPaymentProfile();
  },
  methods: {
    async checkPaymentProfile() {
      // Assign Store Details
      if (this.companyDetails.paymentProfile.activated) {
        this.paymentProfile = this.companyDetails.paymentProfile;
        this.buttonValue = "Update";
      } else {
        this.paymentProfile.company = this.companyDetails.name;
        this.paymentProfile.email = this.user.email;
        var fullName = this.user.name.split(" ");
        this.paymentProfile.firstName = fullName[0];
        this.paymentProfile.lastName =
          fullName[0] == fullName[fullName.length - 1]
            ? ""
            : fullName[fullName.length - 1];
        this.paymentProfile.phone = this.user.phone;

        try {
          await this.$http
            .get(`https://extreme-ip-lookup.com/json/`)
            .then((respo) => {
              this.paymentProfile.state = respo.body.region;

              this.paymentProfile.city = respo.body.city;
            });
        } catch (e) {
          console.log("Error", e);
        }

        this.buttonValue = "Save";
      }
    },
    async setBillingProfile() {
      try {
        if (this.paymentProfile.company == "") {
          return this.$swal({
            type: "warning",
            text: "Company Name can't be blank",
          });
        }

        if (
          this.paymentProfile.firstName == "" ||
          this.paymentProfile.lastName == ""
        ) {
          return this.$swal({
            type: "warning",
            text: "FirstName / LastName can't be blank",
          });
        }

        if (this.paymentProfile.email == "") {
          return this.$swal({
            type: "warning",
            text: "Email can't be blank",
          });
        }

        if (this.paymentProfile.phone == "") {
          return this.$swal({
            type: "warning",
            text: "Phone can't be blank",
          });
        }

        if (this.paymentProfile.address == "") {
          return this.$swal({
            type: "warning",
            text: "Address can't be blank",
          });
        }

        if (this.paymentProfile.city == "") {
          return this.$swal({
            type: "warning",
            text: "City can't be blank",
          });
        }

        if (this.paymentProfile.zip == "") {
          return this.$swal({
            type: "warning",
            text: "Pincode can't be blank",
          });
        }

        this.loader = true;
        var response = {};

        // Check Promocode
        if (this.companyDetails.paymentProfile.promoCode) {
          this.paymentProfile.promoCode = this.companyDetails.paymentProfile.promoCode;
        }

        if (this.buttonValue == "Update") {
          // Contact Profile Update
          await this.$http.post(
            `${this.endpoint}/billings/update-payment-profile`,
            {
              contactId: this.companyDetails.paymentProfile.zohoContactId,
              contactObject: this.paymentProfile,
            }
          );

          //   POST /billings/update-payment-contact-person
          var jsonObj = {
            contactId: this.companyDetails.paymentProfile.zohoContactId,
            contactPersonId: this.companyDetails.paymentProfile
              .zohoContactPersonId,
            contactPersonObject: {
              firstName: this.paymentProfile.firstName,
              lastName: this.paymentProfile.lastName,
              email: this.paymentProfile.email,
              phone: this.paymentProfile.phone,
            },
          };

          response = await this.$http.post(
            `${this.endpoint}/billings/update-payment-contact-person`,
            jsonObj
          );
        } else {
          // Create New Profile
          this.paymentProfile.activated = true;

          response = await this.$http.post(
            `${this.endpoint}/billings/update-payment-profile`,
            {
              contactObject: this.paymentProfile,
            }
          );
        }

        if (response) {
          await this.$store.dispatch("getCompanyDetails", this.user);
          await this.checkPaymentProfile();
          this.loader = false;
          return this.$swal({
            type: "success",
            text: "Your Payment Profile Updated Successfully.",
          });
        }
      } catch (err) {
        this.loader = false;
        console.log("Error: Create Zoho Profile", err);

        return this.$swal({
          type: "error",
          text: err.body.error.message,
        });
      }
    },
  },
};
</script>

<style></style>
