<template>
  <div>
    <!-- <v-card class="rounded my-2 fill-height" style="position: relative;">
      <span
        v-if="discount > 0"
        class="ribbon ribbon-top-right"
        style="margin-top: -69px;padding-right: 0px!important;"
      >
        <span>{{ discount }}% Off</span>
      </span>
      <v-card-title class="white--text my-1 py-1">
        <h3 style="color:#30475A;">
          Purchase Plan
        </h3>
      </v-card-title>
      <v-card-text class="ma-1 pa-2">
        <v-row class="ma-0 pa-0" justify="center">
          <v-col cols="12" md="5" align="center">
            <v-hover v-slot="{ hover }">
              <v-avatar
                :elevation="hover ? 6 : 2"
                size="200"
                color="primarygrad"
              >
                <v-row>
                  <v-col
                    cols="12"
                    align="center"
                    style="color: white;font-size: 30px;"
                  >
                    {{ planCurrency }} {{ perUserPrice }}
                  </v-col>
                  <v-col
                    cols="12"
                    align="center"
                    style="color: white;font-size: 20px;margin-top: -28px;"
                  >
                    Per User
                  </v-col>
                </v-row>
              </v-avatar>
            </v-hover>
          </v-col>
          <v-col cols="12" align="center">
            <h2 class="smallMobFont">
              You will be charged {{ planCurrency }} {{ planPrice }} per
              {{ selectedPlanCycle.slice(0, -2) }}

              <p style="font-size: 12px;">* Taxes Extra</p>
            </h2>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" justify="center">
          <v-col cols="12" md="2" align="center" style="padding-top: 18px;">
            Duration
          </v-col>
          <span v-for="(cycle, index) in godialPlans" :key="index">
            <v-col cols="6" md="2" align="center">
              <v-badge
                v-if="cycle.value == 'halfyearly'"
                bordered
                color="blue"
                icon="mdi-checkbox-marked-circle-outline"
                overlap
              >
                <v-btn
                  depressed
                  :dark="selectedPlanCycle == cycle.value"
                  @click="setCycle(cycle.value)"
                >
                  {{ cycle.name }}
                </v-btn>
              </v-badge>

              <v-btn
                v-else
                depressed
                :dark="selectedPlanCycle == cycle.value"
                @click="setCycle(cycle.value)"
              >
                {{ cycle.name }}
              </v-btn>
            </v-col>
          </span>
        </v-row>

        <v-row class="ma-0 px-0 py-3" justify="center" align="center">
          <v-row>
            <v-col
              cols="12"
              md="5"
              align="right"
              style="padding-top: 2%;"
              class="hidden-xs-only"
            >
              Member Count
            </v-col>
            <v-col cols="4" sm="1" align="right" style="padding-top: 20px;">
              <v-btn
                color="secondary"
                fab
                x-small
                dark
                @click="setUserCount('minus')"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="4" sm="2">
              <v-text-field
                v-model="userCount"
                label="User Count"
                solo
                type="number"
                @keyup="setUserCount"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="1" style="padding-top: 20px;">
              <v-btn
                color="secondary"
                fab
                x-small
                dark
                @click="setUserCount('plus')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="4" sm="1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    style="opacity: 0.7"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    info_outline
                  </v-icon>
                </template>
                <span
                  >For example, if you have 3 members and you want to add one
                  more, make Total Member Count 4. <br />
                  For example, if you have 3 members and you want to reduce,
                  delete the 3rd member and make Total Member Count 2.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-0 pa-0" justify="center">
          <div class="d-flex justify-center">
            <v-btn
              v-if="isPaid"
              class="primarygrad"
              dark
              :disabled="memberUpdate"
              style="color: white!important;"
              @click.native="pay('update')"
              >Update</v-btn
            >

            <v-btn
              v-if="isPaid"
              color="white"
              style="color: #4f5050"
              :disabled="afterUpdate"
              @click.native="cancelSubscription"
              >Cancel</v-btn
            >

            <v-btn
              v-else
              class="primarygrad"
              dark
              :disabled="afterUpdate"
              style="color: white!important;"
              @click.native="pay('new')"
              >Pay</v-btn
            >
          </div>
        </v-row>
      </v-card-actions>
    </v-card> -->

    <v-card class="rounded my-2 fill-height" style="position: relative;">
      <v-card-title class="white--text my-1 py-1 pt-4">
        <h3 style="color:#30475A;">
          Purchase Plan
        </h3>
      </v-card-title>
      <v-card-text class="ma-1 pa-2">
        <span
          v-if="discount > 0"
          class="ribbon ribbon-top-right"
          style="margin-top: -69px;padding-right: 0px!important;"
        >
          <span>{{ discount }}% Off</span>
        </span>

        <div class="duration-container">
          <span v-for="(cycle, index) in godialPlans" :key="index">
            <v-btn
              class="tab-button"
              :class="{ 'active-tab': selectedPlanCycle === cycle.value }"
              @click="setCycle(cycle.value)"
            >
              {{ cycle.name }}
            </v-btn>
          </span>
        </div>

        <div class="price-container" style="padding-top: 20px;">
          <div class="price-details">
            <div class="discounted-price" style="padding-left: 6px;">
              {{ planCurrency }} {{ perUserPrice }}
              <span style="font-size: 16px;color:grey;font-weight: 400;"
                >/user</span
              >
            </div>
          </div>
          <div class="final-price-info" style="padding-top: 20px;">
            You will be charged {{ planCurrency }} {{ planPrice }} (+Taxes) per
            {{ selectedPlanCycle.slice(0, -2) }}
          </div>
        </div>

        <v-row class="ma-0 px-0 py-3 pt-8" justify="center" align="center">
          <v-row>
            <v-col
              cols="12"
              md="5"
              align="right"
              style="padding-top: 2%;"
              class="hidden-xs-only"
            >
              Member Count
            </v-col>
            <v-col cols="3" sm="1" align="right" style="padding-top: 20px;">
              <v-btn
                color="#37cf37"
                fab
                x-small
                dark
                @click="setUserCount('minus')"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="3" sm="2">
              <v-text-field
                v-model="userCount"
                label="User Count"
                solo
                type="number"
                @keyup="setUserCount"
              ></v-text-field>
            </v-col>
            <v-col cols="3" sm="1" style="padding-top: 20px;">
              <v-btn
                color="#37cf37"
                fab
                x-small
                dark
                @click="setUserCount('plus')"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="3" sm="1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    style="opacity: 0.7"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    info_outline
                  </v-icon>
                </template>
                <span
                  >For example, if you have 3 members and you want to add one
                  more, make Total Member Count 4. <br />
                  For example, if you have 3 members and you want to reduce,
                  delete the 3rd member and make Total Member Count 2.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-0 pa-0" justify="center">
          <div class="d-flex justify-center">
            <v-btn
              v-if="isPaid"
              class="primarygrad"
              dark
              :disabled="memberUpdate"
              style="color: white!important;"
              @click.native="pay('update')"
              >Update</v-btn
            >

            <v-btn
              v-if="isPaid"
              color="white"
              style="color: #4f5050"
              :disabled="afterUpdate"
              @click.native="cancelSubscription"
              >Cancel</v-btn
            >

            <v-btn
              v-else
              class="primarygrad"
              dark
              :disabled="afterUpdate"
              style="color: white!important;"
              @click.native="pay('new')"
              >Pay</v-btn
            >
          </div>
        </v-row>
      </v-card-actions>

      <div style="padding-bottom: 30px;"></div>
    </v-card>

    <v-card v-if="billings.length > 0">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="billings" :search="search">
        <template v-slot:item.invoice="{ item }">
          <!-- :color="orange" -->
          <v-chip v-if="item.paid && !item.deleted" dark color="success">
            Paid
          </v-chip>

          <v-chip
            v-else-if="!item.paid && !item.deleted"
            color="red lighten-3"
            dark
            @click="pay(item.invoice)"
          >
            Pay
          </v-chip>

          <v-chip v-else dark color="error">
            Deleted
          </v-chip>

          <!-- <v-chip v-else> </v-chip> -->
        </template>

        <template v-slot:item.createdOn="{ item }">
          {{ $moment(item.createdOn).format("lll") }}
        </template>

        <template v-slot:item.amount="{ item }">
          {{ item.amount }} {{ item.currency }}
        </template>

        <template v-slot:item.paid="{ item }">
          <v-chip v-if="!item.deleted" dark @click="pay(item.invoice)">
            Invoice
          </v-chip>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
            v-if="!item.paid && !item.deleted"
            icon
            color="error"
            @click="removeInvoice(item.id)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import fb from "../../js/firebase";
import countries from "../../js/countries";

export default {
  data() {
    return {
      afterUpdate: false,
      planName: "",
      planExpiry: "",
      planUserLimit: "",
      prices: false,
      planModule: [],
      min: 1,
      max: 1000,
      numberOfMembers: 0,
      ftPlan: true,
      rftPlan: true,
      formsPlan: true,
      securityPlan: true,
      godialPlans: [
        {
          name: "Monthly",
          value: "monthly",
          price: 349,
          zohoItemId: "430149000000121038",
        },
        // {
        //   name: "Half Yearly",
        //   value: "halfyearly",
        //   price: 1799,
        //   zohoItemId: "430149000000194001",
        // },
        {
          name: "Yearly",
          value: "yearly",
          price: 2799,
          zohoItemId: "430149000000121047",
        },
      ],
      planCurrencyType: [
        { label: "USD", value: "USD" },
        { label: "INR", value: "INR" },
      ],
      planCycle: "",
      // planCurrency: "",
      planPrice: 0,
      ftPlanPrice: 0,
      rftPlanPrice: 0,
      formsPlanPrice: 0,
      securityPlanPrice: 0,
      planCycleMonth: 1,
      planPriceFinal: 0,
      companyData: {},

      //isActive: false,
      // currentPrice: "",
      planCurrency: "",
      totalUserCount: 0,
      appSumoCode: "",
      selectedPlanCycle: "",
      userCount: 0,
      billings: [],
      loader: false,
      perUserPrice: 0,
    };
  },
  computed: {
    memberUpdate() {
      //Disable the update button, if the selected no of user is less than or equal to the no of users in the acount

      console.log("this.userCount", this.userCount);

      console.log("this.numberOfMembers", this.numberOfMembers);

      return this.userCount < this.numberOfMembers;
    },
    expired() {
      try {
        var self = this;
        var company = this.$store.getters.companyDetails;
        var expiry = moment(company.plan.expiryDate);
        var now = moment().format("DD/MM/YYYY HH:mm:ss");

        var expiryBeforeThreeDays = moment(company.plan.expiryDate)
          .subtract(0, "day")
          .format("DD/MM/YYYY HH:mm:ss");

        var durationofThreeDays = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
          moment(expiryBeforeThreeDays, "DD/MM/YYYY HH:mm:ss"),
          "seconds"
        );

        var duration = moment().diff(moment(expiry), "seconds");
        if (duration > 0) {
          self.expiryOver = true;
        } else {
          self.expiryOver = false;
        }

        if (durationofThreeDays > 0) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    ...mapGetters(["ENDPOINT", "user", "companyDetails"]),
    isActive() {
      return this.companyDetails.plan.active;
    },
    subscribeKing() {
      return require("../../assets/subscribeKing.png");
    },
    moneySubscribe() {
      return require("../../assets/moneySubscribe.png");
    },
    moneyTrial() {
      return require("../../assets/moneyTrial.png");
    },
    isPaid() {
      try {
        if (this.companyDetails.plan.name == "Godial Premium Manual") {
          return false;
        }

        if (this.companyDetails.plan.active == false) {
          return false;
        }

        return (
          this.companyDetails.plan.serverResponse &&
          this.companyDetails.plan.serverResponse.subscription_id
        );
      } catch (e) {
        return false;
      }
    },
    currentPrice() {
      if (this.companyDetails.plan.serverResponse) {
        return (
          this.companyDetails.plan.serverResponse.currency +
          " " +
          this.companyDetails.plan.serverResponse.sale_gross
        );
      } else {
        return "FREE";
      }
    },
    discount() {
      if (!this.prices) return 0;

      if (this.selectedPlanCycle == "monthly") {
        return 0;
      } else {
        var monthly = _.find(this.prices, (p) => {
          return p.subscription.interval == "month";
        });

        var yearly = _.find(this.prices, (p) => {
          return p.subscription.interval == "year";
        });

        var monthlyPrice = Number(monthly.price.net).toFixed(2);
        var yearlyPrice = Number(yearly.price.net).toFixed(2);

        var discount =
          ((monthlyPrice * 12 - yearlyPrice) / (monthlyPrice * 12)) * 100;

        return discount.toFixed(0);
      }
    },
  },

  watch: {
    companyDetails() {
      if (this.companyDetails.country.trim().toLowerCase() == "india") {
        this.planCurrency = "INR";
      } else {
        this.planCurrency = "USD";
      }
      this.changePrice();
    },
  },
  created() {
    this.refresh();
    const self = this;
    //total no. of users in this company
    this.fetchUserCount();

    this.planName = this.companyDetails.plan.name;

    if (this.companyDetails.plan.cycle == "year") {
      this.selectedPlanCycle = "yearly";
    } else {
      this.selectedPlanCycle = "monthly";
    }

    this.planExpiry = moment(this.companyDetails.plan.expiryDate).format(
      "YYYY-MM-DD"
    );

    this.planPriceFinal = this.companyDetails.plan.amountPerMonth || 0;

    this.planUserLimit = this.companyDetails.plan.userLimit;

    //this.planModule = this.$store.getters.company.plan.module;

    //this.selectedPlanCycle = this.changePrice();

    // this.onSelectSegment([{"value": "Monthly", "label": "Monthly"}])

    this.onSelectSegmentCurrency();

    /*let pdlscript = document.createElement("script");
    pdlscript.setAttribute("src", "https://cdn.paddle.com/paddle/paddle.js");
    document.head.appendChild(pdlscript); */

    function loadScript(url, callback) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      if (script.readyState) {
        // only required for IE <9
        script.onreadystatechange = function() {
          if (
            script.readyState === "loaded" ||
            script.readyState === "complete"
          ) {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {
        //Others
        script.onload = function() {
          callback();
        };
      }

      script.src = url;
      document.getElementsByTagName("head")[0].appendChild(script);
    }

    loadScript("https://cdn.paddle.com/paddle/paddle.js", function() {
      if (self.$store.getters.isPaddleStaging == "true") {
        Paddle.Environment.set("sandbox");
      }

      //alert("Paddle key ", self.$store.getters.paddleKey);
      Paddle.Setup({ vendor: Number(self.$store.getters.paddleKey) });
    });
  },

  methods: {
    setUserCount(type) {
      var self = this;
      if (type == "plus" || type == "minus") {
        this.userCount = type == "plus" ? ++this.userCount : --this.userCount;
      }

      if (Number(this.userCount) <= Number(this.numberOfMembers)) {
        this.userCount = this.numberOfMembers;

        return this.$swal({
          title: "Error!",
          text: `You can not decrease member because you already have ${this.numberOfMembers} members.You need to delete member first to decrease.`,
          type: "error",
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Go to Members",
        })
          .then((willGo) => {
            if (willGo.value) {
              self.$router.push({ path: "/users" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      this.changePrice();
      // this.getBillingCost();
    },
    setCycle(cycle) {
      this.selectedPlanCycle = cycle;
      this.changePrice();
    },
    fetchUserCount() {
      this.userCount = 0;
      this.numberOfMembers = 0;

      var where = {
        companyId: {
          eq: this.$store.getters.user.companyId,
        },
        role: {
          neq: "ApiManager",
        },
        username: {
          neq: "adminhelper",
        },
      };

      this.$http
        .get(
          `${
            this.$store.state.ENDPOINT
          }/accounts/count?where=${encodeURIComponent(JSON.stringify(where))}`
        )
        .then((response) => {
          response = response.body;
          this.userCount = response.count;

          this.$emit("update-original-user-count", this.userCount);
          this.numberOfMembers = this.userCount; //assign user count
        })
        .catch((err) => {
          this.numberOfMembers = 10; // set to default
        });
    },

    onSelectSegment(optionsSelected) {
      var self = this;

      if (optionsSelected[0].value == "Monthly") {
        self.planCycle = "Monthly";
      } else {
        self.planCycle = "Yearly";
      }

      self.changePrice();
    },

    onSelectSegmentCurrency() {
      var self = this;

      self.planCurrency = "INR";

      if (this.companyDetails.country.toLowerCase() == "india") {
        self.planCurrency = "INR";
      } else {
        self.planCurrency = "USD";
      }

      self.changePrice();
    },

    changePrice() {
      if (!this.prices) return;
      const self = this;
      const userCount = Number(this.userCount);

      if (userCount < 1) {
        self.$swal({
          type: "warning",
          text: "Number of members cannot be less than 1",
        });
        self.fetchUserCount();
        return;
      }

      if (this.selectedPlanCycle == "monthly") {
        var monthly = _.find(this.prices, (p) => {
          return p.subscription.interval == "month";
        });

        this.perUserPrice = Number(monthly.price.net);
        this.planPrice = Number(monthly.price.net * userCount).toFixed(2);
        this.planCurrency = monthly.currency;
      } else {
        var yearly = _.find(this.prices, (p) => {
          return p.subscription.interval == "year";
        });

        this.perUserPrice = Number(yearly.price.net);
        this.planPrice = Number(yearly.price.net * userCount).toFixed(2);
        this.planCurrency = yearly.currency;
      }
    },

    pay(mode) {
      const self = this;

      if (self.numberOfMembers < self.totalUserCount) {
        self.$swal({
          type: "warning",
          text:
            "Your account has " +
            self.totalUserCount +
            " members, please increase the no of members",
        });
        return;
      }
      self
        .$swal({
          text:
            mode == "update"
              ? "Updating your account. Your new subscription charge will be auto prorated and charged from your current payment method. Click Ok to proceed"
              : "Your subscription will start after payment is made, Click Ok to proceed.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "info",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        })
        .then(async (result) => {
          if (result.value) {
            self.afterUpdate = true;

            var country = _.find(countries, (c) => {
              return (
                c.name.toLowerCase().trim() ==
                self.companyDetails.country.toLowerCase().trim()
              );
            });

            if (!country) country = "US";
            else country = country.code;

            var productId =
              self.selectedPlanCycle.toLowerCase() == "monthly"
                ? self.$store.getters.monthlyProd
                : self.$store.getters.yearlyProd;

            if (mode == "update") {
              await self.$http.post(`${self.ENDPOINT}/plans/changepaddle`, {
                opts: {
                  userCount: Number(self.userCount),
                  plan_id: productId,
                },
              });

              self.$swal({
                type: "success",
                text:
                  "Update request has been sent. Your account will updated shortly, Kindly refresh after 1 minute.",
              });
              self.afterUpdate = false;
              self.refresh();
            } else {
              Paddle.Checkout.open({
                product: productId,
                email: self.user.email,
                country: country,
                quantity: Number(self.userCount),
                passthrough: self.user.companyId,
                successCallback: function(data) {
                  var checkoutId = data.checkout.id;

                  Paddle.Order.details(checkoutId, function(data2) {
                    // Order data, downloads, receipts etc... available within 'data' variable.
                    try {
                      fb.log("purchase", {
                        item: data2.checkout.title,
                        price: {
                          amount: data2.order.total,
                          currency: data2.order.currency,
                        },
                        currency: data2.order.currency,
                        value: data2.order.total,
                        order_number: data2.order.order_id,
                        subscription_id: data2.order.subscription_id,
                        catalog_img: data2.checkout.image_url,
                      });

                      self.$swal({
                        type: "success",
                        text:
                          "Thank you for subscribing to GoDial. Your account will updated shortly, Kindly refresh after 1 minute.",
                      });

                      self.afterUpdate = false;

                      self.refresh();

                      // this.$gtm.push({ event: "purchase" });
                    } catch (e) {
                      console.log(e);
                    }
                  });
                },
                closeCallback: function(data) {
                  self.afterUpdate = false;

                  self.refresh();

                  fb.log("purchase_cancel");
                },
              });
            }
          }
        });
    },

    autoDetectTimeZone() {
      this.companyDetails.tz.name = moment.tz.guess();
    },

    cancelSubscription() {
      const self = this;

      self
        .$swal({
          title: "Are you sure?",
          text: "Do you want to cancel your subscription?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.value) {
            self.afterUpdate = true;
            self.$http
              .post(`${self.ENDPOINT}/plans/cancelpaddle`)
              .then((response) => {
                self.afterUpdate = false;
                self.refresh();
                // fb.log("cancel_subcription");
              })
              .catch((e) => {
                self.afterUpdate = false;
                self.$swal({
                  type: "Error",
                  text:
                    "Something went wrong while cancelling your subscription",
                });
              });
          }
        });
    },
    refresh() {
      this.$store.dispatch("getCompanyDetails", {
        companyId: this.user.companyId,
      });
      this.loadPrices();
    },

    loadPrices() {
      const self = this;
      var country = _.find(countries, (c) => {
        return (
          c.name.toLowerCase().trim() ==
          self.companyDetails.country.toLowerCase().trim()
        );
      });

      if (!country) country = "US";
      else country = country.code;

      var product_ids = [];

      product_ids.push(
        self.$store.getters.monthlyProd,
        self.$store.getters.yearlyProd
      );

      this.$http
        .post(`${process.env.VUE_APP_ENDPOINT}/billings/get-paddle-product`, {
          customer_country: country,
          productIds: product_ids,
        })
        .then((response) => {
          self.prices = response.body.response.products;
          self.changePrice();
        });
    },
    add() {
      this.$http
        .post(`${this.$store.state.ENDPOINT}/coupons/redeem`, {
          code: this.appSumoCode,
        })
        .then((response) => {
          this.refresh();
        })
        .catch((err) => {
          if (err.body.error && err.body.error.message) {
            this.$swal({ type: "error", text: err.body.error.message });
            return;
          }

          this.$swal({
            type: "error",
            text: "error while redeeming this code ",
          });
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
.gps-btn {
  /* margin-left: 327px;
  z-index: 1;
  top: -35px; */
  margin-left: -190px;
  background: transparent !important;
  box-shadow: none !important;
}
@media only screen and (max-width: 600px) {
  .gps-btn {
    margin-left: -120px;
  }
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.company-save-btn {
  color: #ffffff;
  letter-spacing: 1px;
  margin-bottom: 20px;
  font-size: 16px;
}

.customBorder {
  border-style: solid;
  padding: 5px;
  border-radius: 4px;
}

.v-application--is-ltr .v-list-item__avatar:first-child {
  margin-right: 8px !important;
}

.v-list-item__subtitle,
.v-list-item__title {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  overflow: auto;
  text-overflow: ellipsis !important;
  white-space: pre-line !important;
}

.customCenter {
  justify-content: center !important;
  flex-direction: row !important;
  text-align: center !important;
  align-items: center !important;
}

.flight-types {
  display: flex;
  max-width: 336px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 1;
  margin: 0 auto;
  font-size: 12px;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #fff;
}
.flight-types > input {
  display: none;
}
.flight-types > input:checked + label {
  color: #3395de;
}
.flight-types > input:nth-of-type(1):checked ~ label:last-of-type:before {
  transform: translateX(calc(0% + 0px));
}
.flight-types > input:nth-of-type(2):checked ~ label:last-of-type:before {
  transform: translateX(calc(100% + 0px));
}
.flight-types > input:nth-of-type(3):checked ~ label:last-of-type:before {
  transform: translateX(calc(200% + 0px));
}
.flight-types label {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.flight-types label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(33.3333333333% - 0px);
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translateX(0);
}
.flight-types label {
  padding: 6px 3px;
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:before {
  background: #fff;
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:not(:last-child) {
  border-right: 1px solid #fff;
}

.designBorder {
  border: 1px solid #ccc !important;
  /* padding: 0.01em 16px; */
  border-radius: 16px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.designBorderWhite {
  border: 1px solid #fff !important;
  /* padding: 0.01em 16px; */
  border-radius: 16px;
  box-shadow: 0 10px 20px rgb(245 245 245 / 19%),
    0 6px 6px rgb(234 234 234 / 23%);
}

.discount {
  position: absolute;
  padding: 8px;
  color: rgb(254, 254, 254);
  background-color: rgb(255, 202, 0);
  width: 100%;
  transform: rotate(35deg) translate(40px, -20px);
  top: 0;
  right: 0;
  text-align: center;
}

/* Ribbon ************ */

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: 69px;
  right: 0px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: 1px;
  top: 22px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

@media (min-width: 1200px) {
  .curFlex {
    flex: 0.2 !important;
  }

  .customPadding {
    padding-left: 30%;
    padding-right: 30%;
  }

  .iconPaddingForDesktop {
    padding-top: 8px !important;
  }

  .pricePadding {
    padding-top: 10%;
  }

  .verticalLineCSS {
    padding: 1%;
    border-left: 6px solid #4caf50;
  }

  .paddingPlan {
    padding-top: 7px;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .curFlex {
    flex: 0.2 !important;
  }

  .customPadding {
    padding-left: 30%;
    padding-right: 30%;
  }

  .iconPaddingForDesktop {
    padding-top: 8px !important;
  }

  .pricePadding {
    padding-top: 10%;
  }

  .verticalLineCSS {
    padding: 1%;
    border-left: 6px solid #4caf50;
  }

  .paddingPlan {
    padding-top: 7px;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .curFlex {
    flex: 2 !important;
  }

  .customPadding {
    padding-left: 20%;
    padding-right: 20%;
  }

  .iconPaddingForDesktop {
    padding-top: 8px !important;
  }

  .pricePadding {
    padding-top: 10%;
  }

  .verticalLineCSS {
    padding: 1%;
    border-left: 6px solid #4caf50;
  }

  .paddingPlan {
    padding-top: 7px;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .curFlex {
    flex: 2 !important;
  }

  .customPadding {
    padding-left: 1%;
    padding-right: 1%;
  }

  .verticalLineCSS {
    padding: 1%;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .curFlex {
    flex: 2 !important;
  }

  .customPadding {
    padding-left: 1%;
    padding-right: 1%;
  }

  .verticalLineCSS {
    padding: 1%;
  }
}

.duration-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.tab-button {
  background-color: white;
  color: rgb(155, 155, 155);
  border: 1px solid #37cf37;
  width: 150px;
  height: 90px;
  margin: 0 -2px 0 -2px !important;
  font-weight: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: background-color 0.3s, color 0.3s, border-color 0.3s; */
  border-radius: 0; /* Remove border radius for a flat edge */
  box-shadow: none;
}

.tab-button:first-child {
  /* border-top-left-radius: 10px; 
  border-bottom-left-radius: 10px; */
  margin-left: 0;
}

.tab-button:last-child {
  /* border-top-right-radius: 10px; 
  border-bottom-right-radius: 10px; */
  margin-right: 0;
}

.tab-button:hover {
  background-color: #f7f7f7;
}

.tab-button.active-tab {
  background-color: #37cf37;
  color: white;
  border-color: #37cf37;
}

.price-details {
  display: flex;
  justify-content: center;
  /* align-items: baseline; */
}

.price-container {
  text-align: center;
  margin-top: 20px;
}

.original-price {
  font-size: 16px;
  color: grey;
  text-decoration: line-through;
}

.discounted-price {
  font-size: 36px;
  color: #1d2d50;
  font-weight: bold;
}

.discount-percentage {
  font-size: 20px;
  color: grey;
  font-weight: normal;
}

.final-price-info {
  font-size: 18px;
  color: grey;
  margin-top: 10px;
}

@media (max-width: 767px) {
  .price-container {
    font-size: 14px;
  }

  .original-price {
    font-size: 14px;
  }

  .discounted-price {
    font-size: 28px;
  }

  .discount-percentage {
    font-size: 16px;
  }

  .final-price-info {
    font-size: 16px;
  }

  .tab-button {
    background-color: white;
    color: rgb(155, 155, 155);
    border: 1px solid #37cf37;
    width: 110px;
    height: 90px;
    margin: 0 -2px 0 -2px !important;
    font-weight: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: background-color 0.3s, color 0.3s, border-color 0.3s; */
    border-radius: 0; /* Remove border radius for a flat edge */
    box-shadow: none;
  }
}
</style>
